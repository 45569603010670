const state = {
  userinfo: {
    username: "",
    invite_code: "",
    headpic: "",
  },
  level: {
    name: '',
  },
  balance: 0, // 余额
  profit: 0,
  navTab: "home",
}

export default state
