import * as types from './mutation-types'
import {Userinfo} from "@/api/my";
import Config from "@/config";


// 获取用户信息
export const getUserinfo = ({commit}) => {
  Userinfo().then(res => {
    // 拼接完整的头像地址
    let headpic = res.user.headpic;
    if (headpic.indexOf('http') === -1) {
      headpic = Config.domain + headpic;
      res.user.headpic = headpic;
    }

    commit(types.SET_USERINFO, res.user);
    commit(types.SET_USER_LEVEL, res.level);
    commit(types.SET_BALANCE, res.wallet.balance);
    commit(types.SET_PROFIT, res.wallet.profit);
  })
}

// 获取用户余额
export const getUserBalance = ({commit}) => {
  //  getBalance().then(res => {
  //   commit(types.SET_BALANCE, res.data.balance);
  //   commit(types.SET_MARGIN, res.data.margin);
  //   commit(types.SET_MARGIN_FREE, res.data.marginFree);
  // })
}