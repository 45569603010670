import Vue from "vue";
import Router from "vue-router";
import routes from './routes';



const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}


Vue.use(Router)

const router = new Router({
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
  routes: routes
})

//路由前置守卫相关操作
router.beforeEach((to, from, next) => {

  // 如果不需要登陆则直接进入
  if(to.meta.auth === false){
    return next()
  }

  // 判断token是否过期，如果过期则跳转到登陆页面
  var timestamp = Date.parse(new Date());
  var expireTime = localStorage.getItem('tokenExpire');
  let token = localStorage.getItem('token');
  if(token && timestamp < expireTime){
    return next();
  }

  next({
    path: "/login",
  });
})


export default router