import * as types from './mutation-types'

const mutations = {
  [types.SET_BALANCE](state, balance) {
    state.balance = balance ? balance : 0
  },

  [types.SET_PROFIT](state, profit) {
    state.profit = profit ? profit : 0
  },


  [types.SET_USERINFO](state, userinfo) {
    if(userinfo){
      state.userinfo = userinfo;
    }
  },

  [types.SET_USER_LEVEL](state, level) {
    if(level){
      state.level = level;
    }
  },

  [types.SET_NAV_TAB](state, tab) {
    state.navTab = tab;
  },
}

export default mutations;
